import React from "react"
import { Label, InputCore, Check } from "@buslinker/ui"
import { FaExclamationCircle } from "@react-icons/all-files/fa/FaExclamationCircle"
import { Tooltip } from 'react-tooltip'

export const FormField = ({
  name,
  label,
  type = "text",
  formik,
  value,
  placeholder,
}: {
  formik: any
  name: string
  label?: string
  type?: string
  value: any
  placeholder?: string
}) => {
  const error = formik.errors[name]
  const touched = formik.touched[name]

  return (
    <div className="relative">
      {type === "checkbox" ? (
        <Check
          id={name}
          name={name}
          value={value}
          onChange={formik.handleChange}
          label={label}
        />
      ) : (
        <>
          <Label color={error && touched ? "red" : "gray-light"}>{label}</Label>
          <InputCore
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            aria-describedby={error && touched ? `${name}-error` : undefined}
          />
          {error && touched && (
            <span
              className="absolute right-0 top-0 mt-9 mr-3 text-red-500 cursor-pointer"
              data-tooltip-content={error}
              data-tooltip-id={`${name}-tooltip`}
            >
              <FaExclamationCircle />
            </span>
          )}
          {error && touched && (
            <Tooltip id={`${name}-tooltip`} place="top" effect="solid" />
          )}
        </>
      )}
    </div>
  )
}

import { ReservationType } from "@buslinker/shared"

export const getPrice = (priceInRs: number) => priceInRs.toFixed(0);

export const getTotalPrice = (reservation: ReservationType) => {
  const total = reservation.reservedTrips
    .reduce((acc, trip) => acc + +getPrice(trip.trip.price), 0)
    .toFixed(0);

  return total;
}

export const getTotalPriceWithDiscount = (reservation: ReservationType) => {
  const discountMultiplier = 1 - (reservation.discount || 0) / 100
  const total =
    reservation.reservedTrips
      .reduce((acc, trip) => acc + +getPrice(trip.trip.price), 0) * discountMultiplier

  return total.toFixed(0);
}